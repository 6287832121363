import { useState, useEffect } from 'react';
import useStyles from './styles';
import MediaPreview, {
  MEDIA_TYPE_360,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
} from '../media-preview';
import {
  Close as CloseIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import { ButtonBase } from '@material-ui/core';
import clsx from 'classnames';

const TRANSITION_DURATION = 400; // 0.4 secs

function PreviewOverlay(props) {
  const classes = useStyles();
  const { data, initialItemIndex, onClose } = props;
  const [deviceInLandscape, setDeviceInLandscape] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(initialItemIndex ?? 0);
  const [thumbnailAnimationClass, setThumbnailAnimationClass] =
    useState('fade-in');
  const showPrevious = () => setCurrentIndex(Math.max(currentIndex - 1, 0));
  const showNext = () =>
    setCurrentIndex(Math.min(currentIndex + 1, data.length - 1));

  const [sliding, setSliding] = useState(false);
  const handlePrevClick = () => {
    if (sliding) return;
    setThumbnailAnimationClass('slide-out-right');
    setSliding(true);
    setTimeout(() => {
      showPrevious();
      setThumbnailAnimationClass('fade-in');
      setSliding(false);
    }, TRANSITION_DURATION);
  };
  const handleNextClick = () => {
    if (sliding) return;
    setThumbnailAnimationClass('slide-out-left');
    setSliding(true);
    setTimeout(() => {
      showNext();
      setThumbnailAnimationClass('fade-in');
      setSliding(false);
    }, TRANSITION_DURATION);
  };

  useEffect(() => {
    window.onresize = () => {
      setDeviceInLandscape(hasLandscapeOrientation());
    };
  }, []);

  const mediaProps = {
    common: {
      className: !deviceInLandscape ? 'corner-round' : '',
    },
    [MEDIA_TYPE_IMAGE]: {
      layout: 'fill',
    },
    [MEDIA_TYPE_VIDEO]: {
      autoPlay: true,
      controls: true,
    },
    [MEDIA_TYPE_360]: {},
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        onClick={onClose ?? (() => ({}))}
        className={classes.closeButton}
        aria-label="close preview">
        <CloseIcon fontSize="small" />
      </ButtonBase>
      <div
        className={clsx(
          classes.content,
          deviceInLandscape ? 'landscape' : 'portrait'
        )}>
        {deviceInLandscape && (
          <div className={clsx(classes.sliderAction, 'left')}>
            <ButtonBase
              className={clsx(
                'action',
                'prev',
                currentIndex > 0 ? '' : 'hidden'
              )}
              aria-label="previous image"
              onClick={handlePrevClick}>
              <ChevronLeftIcon fontSize="inherit" />
            </ButtonBase>
          </div>
        )}
        <div
          className={clsx(
            classes.thumbnail,
            deviceInLandscape ? `land ${thumbnailAnimationClass}` : ''
          )}>
          <MediaPreview
            {...mediaProps.common}
            {...mediaProps[data[currentIndex]?.type]}
            type={data[currentIndex]?.type}
            url={data[currentIndex]?.url}
            alt={data[currentIndex]?.subCategory}
          />
        </div>
        {deviceInLandscape && (
          <div className={clsx(classes.sliderAction, 'right')}>
            <ButtonBase
              className={clsx(
                'action',
                'next',
                currentIndex < data.length - 1 ? '' : 'hidden'
              )}
              aria-label="next image"
              onClick={handleNextClick}>
              <ChevronRightIcon fontSize="inherit" />
            </ButtonBase>
          </div>
        )}
        {!deviceInLandscape && (
          <p className={classes.infoText}>
            Turn the mobile to view full screen
          </p>
        )}
      </div>
    </div>
  );
}

function hasLandscapeOrientation() {
  return window.screen.width > window.screen.height;
}

export default PreviewOverlay;
