import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    fontFamily: 'Open Sans, sans-serif !important',
    position: 'fixed',
    top: 0,
    left: 0,
    minWidth: '100vw',
    maxWidth: '100vw',
    overflowX: 'hidden',
    minHeight: '100vh',
    maxHeight: '100vh',
    overFlowY: 'auto',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexFlow: 'column nowrap',
    zIndex: '50',
  },
  header: {
    backgroundColor: '#ffffff',
    minWidth: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0 1.25rem',
  },
  actionBar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    '& .back-title': {
      display: 'flex',
      textTransform: 'capitalize',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      '& > .title': {
        fontSize: '16px',
        fontWeight: '700',
      },
    },
    '& .actions': {
      display: 'flex',
      flowFlow: 'row nowrap',
      alignItems: 'center',
    },
  },

  contentBody: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: '1 1 100%',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#FCFCFF',
    borderTopLeftRadius: '0.8rem',
    borderTopRightRadius: '0.8rem',
    border: '1px solid #eeeeee',
    boxShadow: '2px 2px 12px #00000029',
    padding: '1rem 1.25rem 1rem 1.25rem',
    marginTop: '1rem',
    paddingBottom: '7em',
  },

  imageList: {
    '&:not(:first-child)': {
      marginTop: '1rem',
    },
    '& > .heading': {
      margin: '0 0 0.75rem 0',
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '0.95rem',
      fontWeight: '700',
      textTransform: 'capitalize',
    },
    '& > .thumbnails': {
      display: 'flex',
      flexFlow: 'row wrap',
      gap: '0.5rem',
      '& .corner-round': {
        borderRadius: '0.5rem',
      },
    },
  },

  infoText: {
    color: '#888888',
    fontSize: '0.8rem',
  },
});
