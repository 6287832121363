import Image from 'next/image';
import { PlayCircleOutline as PlayIcon } from '@material-ui/icons';
import useStyles from './styles';
import clsx from 'classnames';

export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_VIDEO = 'video';
export const MEDIA_TYPE_360 = '360';
export const MSG_MEDIA_NOT_SUPPORTED = 'media not supported';

/*
It renders supported media using appropriate component.
Props must contain `type` attribute with one of supported media type value.
Props must contain `url` attribute for the media to render.
*/
function MediaPreview({ type, url, ...restProps }) {
  const classes = useStyles();

  const imageLoader = ({ src, width, height }) => {
    const queryValue = [];
    if (width) queryValue.push(`w-${width}`);
    if (height) queryValue.push(`h-${height}`);
    return queryValue.length === 0 ? src : `${src}?tr=${queryValue.join(',')}`;
  };

  switch (type) {
    case MEDIA_TYPE_IMAGE:
      return (
        <Image
          id={restProps.id}
          className={restProps.className}
          src={url}
          layout={restProps.layout}
          height={parseInt(restProps.height)}
          width={parseInt(restProps.width)}
          alt={restProps.alt}
          title={restProps.title}
          unoptimized={restProps.unoptimized}
          loader={imageLoader}
          onClick={restProps.onClick}
          objectFit="scale-down"
        />
      );
    case MEDIA_TYPE_VIDEO:
      return (
        <div
          id={restProps.id}
          className={clsx(classes.videoContainer, restProps.className)}
          style={restProps.style}>
          <video
            title={restProps.title}
            preload="metadata"
            loop={restProps.loop}
            poster={restProps.poster}
            controls={restProps.controls}
            autoPlay={restProps.autoPlay}
            onClick={restProps.onClick}>
            <source src={url} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
          {restProps.overlay && (
            <span
              onClick={restProps.onOverlayClick}
              className={classes.overlay}>
              <PlayIcon className="icon" />
            </span>
          )}
        </div>
      );
    case MEDIA_TYPE_360:
      return (
        <div
          id={restProps.id}
          className={clsx(classes.iframeContainer, restProps.className)}
          style={restProps.style}>
          <iframe
            src={url}
            title={restProps.title}
            height={restProps.height}
            width={restProps.width}
            onClick={restProps.onClick}></iframe>
          {restProps.overlay && (
            <span
              onClick={restProps.onOverlayClick}
              className={classes.overlay}>
              <PlayIcon className="icon" />
            </span>
          )}
        </div>
      );
    default:
      // UNKNOWN MEDIA TYPE
      throw new Error(
        `${MSG_MEDIA_NOT_SUPPORTED}: ${type}. Must be one of ${KNOWN_MEDIA_TYPES.join(
          '|'
        )}`
      );
  }
}
export const KNOWN_MEDIA_TYPES = [
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_360,
];
export default MediaPreview;
