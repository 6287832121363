import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },
    '& .react-horizontal-scrolling-menu--scroll-container': {
      '-ms-overflow-style': 'none' /* IE and Edge */,
      'scrollbar-width': 'none' /* Firefox */,
      overflow: 'hidden',
    },
    '& .react-horizontal-scrolling-menu--wrapper': {
      position: 'relative',
      height: '100%',
      alignItems: 'center',
      display: 'flex',
    },
    height: '100%',
  },
  scrollItem: {
    display: 'block',
    whiteSpace: 'nowrap',
    fontFamily: 'inherit',
    border: '1px solid #D2DEEB',
    padding: '0.6rem 0.5rem',
    borderRadius: '0.35rem',
    textTransform: 'capitalize',
    margin: '0 0.25rem',
  },
  scrollRoot: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
    alignItems: 'center',
  },
  scrollRootPriceDetail: {
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
    alignItems: 'center',
    width: '100%',
  },
  scrollButtonRoot: {
    display: 'inline-block',
    position: 'absolute',
    padding: 0,
    zIndex: 2,
    transition: 'opacity linear 0.1s, visibility linear 0.1s',
    '&.disabled': {
      opacity: 0,
      visibility: 'hidden',
    },
    '&.left': {
      left: 0,
      paddingRight: '1.8rem',
      background:
        'linear-gradient(to right, #ffffffff, #ffffffff,#ffffffdd , #f000);',
    },
    '&.right': {
      right: '0',
      paddingLeft: '1.8rem',
      background:
        'linear-gradient(to right, #f000, #ffffffdd ,#ffffffff, #ffffffff);',
    },
  },
  scrollButton: {
    fontSize: '0',
    padding: '0',
    border: '1px solid #E3EAF5',
    borderRadius: '100rem',
    backgroundColor: '#fff',
    cursor: 'pointer',
    userSelect: 'none',
  },
  active: {
    '&.nav-link li::after': {
      color: '#0056B2',
      //borderBottom: '7px solid #ddd',
    },
    color: '#0056B2',
    //borderBottom: '7px solid #ddd',
    '& li': {
      color: '#0056B2',
      borderBottom: '3px solid #0056B2',
      marginTop: '5px',

      [theme.breakpoints.down('sm')]: {
        marginTop: '2px',
      },
    },
  },
}));
