import React from 'react';
import useStyles from './styles';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import ButtonBase from '@mui/material/ButtonBase';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'classnames';
import PropTypes from 'prop-types';

function ScrollButton(props) {
  const { icon, type, disabled, onClick, buttonRootStyles, buttonStyles } =
    props;

  return (
    <div className={clsx(buttonRootStyles, type, disabled ? 'disabled' : '')}>
      <button className={buttonStyles} onClick={onClick}>
        {icon}
      </button>
    </div>
  );
}

function LeftScrollButton(props) {
  const { buttonRootStyles, buttonStyles, button } = props;
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete,
  } = React.useContext(VisibilityContext);
  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );

  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators?.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ScrollButton
      type="left"
      disabled={disabled}
      onClick={() => scrollPrev()}
      icon={button || <ChevronLeftIcon fontSize="large" />}
      buttonRootStyles={buttonRootStyles}
      buttonStyles={buttonStyles}
    />
  );
}

function RightScrollButton(props) {
  const { buttonRootStyles, buttonStyles, button } = props;
  const { isLastItemVisible, scrollNext, visibleItemsWithoutSeparators } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators?.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators?.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);
  return (
    <ScrollButton
      type="right"
      disabled={disabled}
      onClick={() => scrollNext()}
      icon={button || <ChevronRightIcon fontSize="large" />}
      buttonRootStyles={buttonRootStyles}
      buttonStyles={buttonStyles}
    />
  );
}

function MenuItem(props) {
  const classes = useStyles();
  const { label, onClick, itemStyles } = props;
  return (
    <ButtonBase
      className={classes.scrollItem}
      onClick={onClick}
      style={itemStyles}>
      {label}
    </ButtonBase>
  );
}

function HorizontalScrollMenu(props) {
  const classes = useStyles();
  const items = props.menuitems ?? [];
  const onItemClick = props.onItemClick ?? (() => {});
  const scrollButtons = props.scrollButtons ?? true;
  const itemStyles = props.itemStyles ?? {};
  const rootStyles = props.rootStyles ?? {};
  const wheelScroll = props.wheelScroll ?? true;
  const buttonStyles = props.buttonStyles ?? classes.scrollButton;
  const buttonRootStyles = props.buttonRootStyles ?? classes.scrollButtonRoot;

  return (
    <div className={classes.root} style={rootStyles}>
      <ScrollMenu
        scrollContainerClassName={
          props.Tab === 'price-detail'
            ? classes.scrollRootPriceDetail
            : classes.scrollRoot
        }
        LeftArrow={
          scrollButtons ? (
            <LeftScrollButton
              buttonRootStyles={clsx([
                buttonRootStyles,
                props.leftBtnRootClass,
              ])}
              buttonStyles={buttonStyles}
              button={props.leftButton}
            />
          ) : null
        }
        RightArrow={
          scrollButtons ? (
            <RightScrollButton
              buttonRootStyles={clsx([
                buttonRootStyles,
                props.rightBtnRootClass,
              ])}
              buttonStyles={buttonStyles}
              button={props.rightButton}
            />
          ) : null
        }
        onWheel={wheelScroll ? onWheel : () => {}}>
        {items &&
          items.map((item, i) =>
            typeof item === 'string' ? (
              <MenuItem
                itemStyles={itemStyles}
                itemId={item}
                label={item}
                key={item}
                onClick={() => onItemClick(item, i)}
              />
            ) : (
              <span itemID={'menuitem-' + i} key={'menuitem-' + i}>
                {item}
              </span>
            )
          )}
        {!items && props.children && <>{props.children}</>}
      </ScrollMenu>
    </div>
  );
}

function onWheel(apiObj, ev) {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

HorizontalScrollMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  itemStyles: PropTypes.objectOf(PropTypes.string),
  onItemClick: PropTypes.func,
  onWheels: PropTypes.bool,
};

export default HorizontalScrollMenu;
