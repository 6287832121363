import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  iframeContainer: {
    border: '1px solid #ccc',
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    '& iframe': {
      position: 'absolute',
      border: 'none',
      borderRadius: 'inherit',
      zIndex: '1',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  videoContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #ccc',
    position: 'relative',
    '& video': {
      borderRadius: 'inherit',
      objectFit: 'cover',
      width: '100%',
      height: 'auto',
    },
  },
  overlay: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'inherit',
    position: 'absolute',
    zIndex: '2',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#000A',
    color: '#aaa',

    '& .icon': {
      fontSize: '2.5rem',
    },
  },
}));
