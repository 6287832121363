import { makeStyles } from '@material-ui/core';

export default makeStyles({
  '@global': {
    '@keyframes anim-slide-out-left': {
      '0%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '50%': {
        opacity: 0.5,
        transform: 'translateX(-4rem)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateX(-8rem)',
        visibility: 'hidden',
      },
    },
    '@keyframes anim-slide-out-right': {
      '0%': {
        opacity: 1,
        transform: 'translateX(0)',
      },
      '50%': {
        opacity: 0.5,
        transform: 'translateX(4rem)',
      },
      '100%': {
        opacity: 0,
        transform: 'translateX(8rem)',
        visibility: 'hidden',
      },
    },
    '@keyframes anim-fade-in': {
      '0%': {
        opacity: 0,
        visibility: 'visible',
        transform: 'translateX(0)',
      },
      '50%': {
        opacity: 0.5,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  root: {
    fontFamily: 'Open Sans, sans-serif !important',
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    // to-check
    backgroundColor: '#555555E6',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginRight: '1rem',
    marginTop: '0.8rem',
    color: '#272727',
    backgroundColor: '#FFFFFF8E',
    border: '1px solid #707070',
    borderRadius: '5rem',
    padding: '0.2rem',
  },
  content: {
    display: 'flex',
    '&.portrait': {
      flexFlow: 'column nowrap',
      alignItems: 'center',
      gap: '1rem',
      padding: '0 1.5rem',
      width: '100%',
      height: '40%',
    },
    '&.landscape': {
      flexFlow: 'row nowrap',
      height: '100%',
      backgroundColor: '#151515',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  thumbnail: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& .corner-round': {
      borderRadius: '0.3rem',
    },
    '&.land': {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        flexGrow: 1,
        flexShrink: 1,
      },
      '& img, video': {
        objectFit: 'contain',
      },
      '& .corner-round': {
        borderRadius: 0,
      },
    },
    '&.slide-out-left': {
      animation: '0.4s linear anim-slide-out-left 1',
    },
    '&.slide-out-right': {
      animation: '0.4s linear anim-slide-out-right 1',
    },
    '&.fade-in': {
      animation: '0.5s ease-in anim-fade-in 1;',
    },
  },
  infoText: {
    display: 'inline-block',
    padding: '0.35rem 1.5rem',
    fontSize: '0.7rem',
    fontWeight: '700',
    fontFamily: 'Open Sans, SemiBold, sans-serif',
    backgroundColor: '#FFFFFF8D',
    border: '1px solid #707070',
    borderRadius: '5rem',
    margin: '0 auto',
  },
  sliderAction: {
    width: '8rem',
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '&.left': {
      justifyContent: 'left',
    },
    '&.right': {
      justifyContent: 'right',
    },
    '& .action': {
      fontSize: '3.5rem',
      padding: '0.6rem 0',
      color: '#E3E3E3',
      backgroundColor: '#6A6A6A8E',
      transition: 'opacity linear 0.2s, visibility linear 0.2s',
      '&.prev': {
        marginLeft: '-0.6rem',
        borderTopRightRadius: '12rem',
        borderBottomRightRadius: '12rem',
      },
      '&.next': {
        marginRight: '-0.6rem',
        borderTopLeftRadius: '12rem',
        borderBottomLeftRadius: '12rem',
      },
      '&.hidden': {
        opacity: 0,
        visibility: 'hidden',
      },
    },
  },
});
